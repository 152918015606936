<template>
  <div>
    <!-- 小程序路径对话框 -->
    <el-dialog
      title="功能页面路径列表"
      :visible.sync="showDialog"
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="coupon-activity">
        <el-table
          :data="dataList"
          border
          stripe
          :max-height="tableMaxHeight"
          style="width: 100%;"
          size="mini"
        >
          <el-table-column
            prop="pageName"
            label="页面名称"
            width="140"
          ></el-table-column>
          <el-table-column label="可使用" width="180">
            <template slot-scope="scope">
              <el-tag size="medium" v-show="scope.row.common != 1"
                >自助消费</el-tag
              >
              <el-tag type="danger" size="medium" v-show="scope.row.common != 0"
                >微助理</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="页面路径">
            <template slot-scope="scoped">{{
              scoped.row.selectGuid == undefined
                ? scoped.row.pageLink
                : `${scoped.row.pageLink}${scoped.row.selectGuid}`
            }}</template>
          </el-table-column>
          <el-table-column width="180">
            <template slot-scope="scoped">
              <el-button
                v-if="scoped.row.selectGuid != undefined"
                type="primary"
                size="mini"
                plain
                @click="onShowItemDialog(scoped.row, scoped.$index)"
                >{{scoped.row.selectBtnText}}</el-button
              >

              <el-button
                v-else
                type="primary"
                size="mini"
                @click="onClipboard(scoped.row.pageLink)"
                >复制</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 选择项目对话框 -->
    <DfGoodsDialog
      ref="DfGoodsDialog"
      :multiple="false"
      @onConfirm="onSelectItems($event, 'ItemGuid')"
    ></DfGoodsDialog>

    <!-- 选择会员卡对话框 -->
    <DfCardDialog
      ref="DfCardDialog"
      :multiple="false"
      @onConfirm="onSelectItems($event, 'CardGuid')"
    ></DfCardDialog>

    <!-- 选择问卷对话框 -->
    <DfUsageQuestion
      ref="DfUsageQuestion"
      :multiple="false"
      @onConfirm="onSelectItems($event, 'UsageId')"
    ></DfUsageQuestion>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableMaxHeight: "500px",  // 表格最大高度
      selectIndex: -1,  // 当前下标
      dataList: [ // 小程序路径列表
        {
          pageName: "自定义介绍页",
          pageLink: "pages/others/custom-template?page_id=",
          common: 2,
        },
        {
          pageName: "领券功能",
          pageLink: "pages/others/extension/claim-coupon",
          common: 2,
        },
        {
          pageName: "开指定会员卡",
          pageLink: "pages/card/detail-card?card_guid=",
          selectGuid: "",
          selectItems: [],
          selectBtnText: '选择指定会员卡',
          ref: "DfCardDialog",
          common: 0,
        },
        {
          pageName: "开储值卡",
          pageLink: "pages/card/detail-card",
          common: 0,
        },
        {
          pageName: "开疗程卡",
          pageLink: "pages/card/sale-card",
          common: 0,
        },
        {
          pageName: "我的卡项(充值)",
          pageLink: "pages/card/use-card-item",
          common: 2,
        },
        {
          pageName: "待付订单",
          pageLink: "pages/others/orders/current-bill",
          common: 0,
        },
        {
          pageName: "我的预约",
          pageLink: "pages/reservation/mine-list",
          common: 2,
        },
        {
          pageName: "历史消费",
          pageLink: "pages/transaction/transaction",
          common: 2,
        },
        {
          pageName: "积分商城",
          pageLink: "pages/inte/inte-shopping",
          common: 2,
        },
        {
          pageName: "我的优惠券",
          pageLink: "pages/coupon/mine-list",
          common: 2,
        },
        {
          pageName: "取单",
          pageLink: "pages/expense/fetch-bill",
          common: 0,
        },
        {
          pageName: "一键WiFi",
          pageLink: "WiFi",
          common: 2,
        },
        {
          pageName: "购买指定项目",
          pageLink: "pages/expense/expense-detail?item_guid=",
          selectGuid: "",
          selectItems: [],
          selectBtnText: '选择指定项目',
          ref: "DfGoodsDialog",
          common: 0,
        },
        {
          pageName: "分类商品列表",
          pageLink: "pages5/shopping/shopping-list?category_id=",
          common: 0,
        },
        {
          pageName: "抽奖活动",
          pageLink: "pages5/marketing/raffle/raffle",
          common: 0,
        },
        {
          pageName: "问卷调查",
          pageLink: "pages5/marketing/questionnaire/questionnaire?usage_id=",
          selectGuid: "",
          selectItems: [],
          selectBtnText: '选择指定问卷',
          ref: "DfUsageQuestion",
          common: 0,
        }
      ],
      showDialog: false,  // 对话框显示
      submitDisabled: false,  // 提交防抖
    };
  },

  methods: {
    // 复制文本到剪贴板
    onClipboard(text) {
      let that = this;
      this.$copyText(text).then(() => {
        that.$message.success("已复制到剪贴板!");
      });
    },

    // 选中消费项目、会员卡
    onSelectItems(event, keyName) {
      let param = this.dataList[this.selectIndex];
      param.selectItems = event.selectItems;
      param.selectGuid = event.selectItems[0][keyName];
      this.onClipboard(param.pageLink + param.selectGuid);
    },

    // 打开选择消费项目、会员卡对话框
    onShowItemDialog(event, index) {
      this.selectIndex = index;
      this.$refs[event.ref].onShowDialog(event.selectItems);
    },

    // 打开对话框
    onShowDialog() {
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-activity {
  .el-tag {
    margin-right: 10px;
  }
}
</style>
