import {
  request
} from "@/utils/axios.js"

export default {
  // 项目 ==> 选择项目
  quickSelector(params = {}) {
    return request('GET', `/item/quick_selector`, params)
  },

  initSortItems(params = {}) {
    return request('GET', `/item/init_sort_items`, params)
  },

  saveShowSortBatch(params = {}) {
    return request('POST', `/item/save_show_sort_batch`, params)
  },

  initSortShows(params = {}) {
    return request('GET', `/item/init_sort_shows`, params)
  },

  getItemshowFlat(params = {}) {
    return request('GET', `/item/get_itemshow_flat`, params)
  },

  // saveShowSortBatch(params = {}) {
  //   return request('POST', `/item/save_show_sort_batch`, params)
  // },

  // 会员 ==> 顾客营销 ==> 售后跟踪
  getTrackSettings(params = {}) {
    return request('GET', `/item/get_track_settings`, params)
  },

  addTrackSetting(params = {}) {
    return request('POST', `/item/add_track_setting`, params)
  },

  updateTrackSetting(params = {}) {
    return request('POST', `/item/update_track_setting`, params)
  },

  deleteTrackSetting(params = {}) {
    return request('GET', `/item/delete_track_setting`, params)
  },

  getStoreSelector(params = {}) {
    return request('GET', `/item/get_store_selector`, params)
  },

  copyTrackSettingTo(params = {}) {
    return request('POST', `/item/copy_track_setting_to`, params)
  },

  // 项目 ==> 提成设置 ==> 项目提成
  getSettingList(params = {}) {
    return request('GET', `/itemroya/get_setting_list`, params)
  },

  initSaveSetting(params = {}) {
    return request('GET', `/itemroya/init_save_setting`, params)
  },

  getCascadeLevel(params = {}) {
    return request('GET', `/itemroya/get_cascade_level`, params)
  },

  getCascadePayment(params = {}) {
    return request('GET', `/itemroya/get_cascade_payment`, params)
  },

  getCascadeAcctype(params = {}) {
    return request('GET', `/itemroya/get_cascade_acctype`, params)
  },

  saveSingleSetting(params = {}) {
    return request('POST', `/itemroya/save_single_setting`, params)
  },

  saveBatchSetting(params = {}) {
    return request('POST', `/itemroya/save_batch_setting`, params)
  },

  saveRoyaElements(params = {}) {
    return request('POST', `/itemroya/save_roya_elements`, params)
  },

  deleteSetting(params = {}) {
    return request('GET', `/itemroya/delete_setting`, params)
  },
  
  synItemRoya(auth_key, auth_code, params = {}) {
    return request('POST', `/itemroya/syn_item_roya?auth_key=${auth_key}&auth_code=${auth_code}`, params)
  },

}