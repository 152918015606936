import {
	request
} from "../utils/axios";

export default {
    // 营销 ====> 问卷列表
	getThemeMaintList(params) {
		return request('get', '/survey/get_theme_maint_list', params);
	},
	
    initTheme(params) {
		return request('get', '/survey/init_theme', params);
	},
	
    saveTheme(params) {
		return request('post', '/survey/save_theme', params);
	},
	
    deleteTheme(params) {
		return request('get', '/survey/delete_theme', params);
	},
	
    getRenderData(params) {
		return request('get', '/survey/get_render_data', params);
	},
	
    intQuestionEditor(params) {
		return request('get', '/survey/int_question_editor', params);
	},
	
    saveQuestionEditor(params) {
		return request('post', '/survey/save_question_editor', params);
	},
	
    deleteQuestion(params) {
		return request('get', '/survey/delete_question', params);
	},

    enableStoreUsage(params) {
		return request('get', '/survey/enable_store_usage', params);
	},

	// 营销 ====> 使用记录
	getStoreUsageMaintList(params) {
		return request('get', '/survey/get_store_usage_maint_list', params);
	},

	disableStoreUsage(params) {
		return request('get', '/survey/disable_store_usage', params);
	},
	
	getUsageFormList(params) {
		return request('get', '/survey/get_usage_form_list', params);
	},
	
	getFormDetail(params) {
		return request('get', '/survey/get_form_detail', params);
	},
	
	getUsageStatisData(params) {
		return request('get', '/survey/get_usage_statis_data', params);
	},
	
	getUsageTextList(params) {
		return request('get', '/survey/get_usage_text_list', params);
	},
}